import React from "react"
import { graphql } from "gatsby"

import Layout from "@components/Layout"
import ImageWithHeading2 from "@components/ImageWithHeading2"

import {
  CollaborationBlock,
  ThreePieceLayoutBlock,
  TwoPieceLayoutBlock,
  ImageBlock,
  TextBlock,
} from "@components/Kibera"

import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import client from "../../components/appolloClient";


const kibera = gql`
  query MyQuery {
    entries(slug: "kibera") {
      id
      uri
      slug
      typeHandle
      ... on stories_kiberaLayout_Entry {
        id
        heroImage {
          ... on heroImage_heroImage_BlockType {
            id
            image {
              ... on uploads_Asset {
                url
                title
                hasFocalPoint
              }
            }
          }
        }
        kiberaStoryBuilder {
          ... on kiberaStoryBuilder_image_BlockType {
            id
            image {
              id
              url
              title
              hasFocalPoint
            }
            mobileImage {
              id
              ... on uploads_Asset {
                url
                title
                hasFocalPoint
              }
            }
            imageSkew {
              ... on imageSkew_BlockType {
                id
                skewPosition
                skewDirection
              }
            }
            margin {
              ... on margin_BlockType {
                id
                marginPosition
                marginSize
              }
            }
            parallaxThumbnails {
              ... on parallaxThumbnails_BlockType {
                id
                thumbnail {
                  ... on uploads_Asset {
                    id
                    url
                    title
                    hasFocalPoint
                  }
                }
                thumbnailSize
                horizontalAlignment
                verticalAlignment
                negativeOffset
              }
            }
            mobileThumbnailsPosition
            mobileThumbnails {
              ... on mobileThumbnails_BlockType {
                id
                thumbnail {
                  id
                  ... on uploads_Asset {
                    id
                    url
                    title
                    hasFocalPoint
                  }
                }
                thumbnailSize
                horizontalAlignment
                negativeOffset
              }
            }
            mobileImageSkew {
              ... on mobileImageSkew_BlockType {
                id
                skewPosition
                skewDirection
              }
            }
            mobileMargin {
              ... on mobileMargin_BlockType {
                id
                marginPosition
                marginSize
              }
            }
            mobileWidth
            mobileLayout
            fullHeight
            typeHandle
          }
          ... on kiberaStoryBuilder_text_BlockType {
            id
            fullHeight
            titleSize
            textAlignment
            textField
            width
            typeHandle
            titleText
          }
          ... on kiberaStoryBuilder_twoPieceLayout_BlockType {
            id
            titleText
            textField
            titleSize
            textPosition
            textOnImage
            caption
            imageUrl {
              url
            }
            image {
              id
              ... on uploads_Asset {
                id
                url
                title
                hasFocalPoint
              }
            }
            typeHandle
            fullHeight
          }
          ... on kiberaStoryBuilder_threePieceLayout_BlockType {
            id
            typeHandle
            titleText
            textField
            titleSize
            secondaryTextField
            textPosition
            captionLeft
            captionRight
            textOnImageLeft
            textOnImageRight
            leftImageSize
            rightImageSize
            leftUrl {
              url
            }
            rightUrl {
              url
            }
            leftImage {
              id
              ... on uploads_Asset {
                id
                url
                title
                hasFocalPoint
              }
            }
            rightImage {
              ... on uploads_Asset {
                id
                url
                title
                hasFocalPoint
              }
            }
          }
          ... on kiberaStoryBuilder_collaboration_BlockType {
            id
            titleField
            typeHandle
            introduction
            description
            leftImage {
              id
              ... on uploads_Asset {
                id
                url
                title
                hasFocalPoint
              }
            }
            rightImage {
              id
              url
              title
              hasFocalPoint
            }
            leftImageCaption
            rightImageCaption
          }
        }
      }
    }
    seomatic {
      metaTitleContainer
      metaTagContainer
      metaLinkContainer
      metaScriptContainer
      metaJsonLdContainer
      metaSiteVarsContainer
      frontendTemplateContainer
    }
  }
`;

const KiberaPage = () => {

  const { loading, error, data } = useQuery(kibera, { client });

  if (loading) {
    return <p>.</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

 


  const { entries: entry } = data
  const { seomatic: seomatic } = data


 
  const {
    heroImage: [heroImage],
  } = entry[0]

 

  const headerComponent = (
    <ImageWithHeading2
    className="h-screen"
    tag="h1"
    position="bottom"
    sources={heroImage.image?.[0]?.url}
    heading={heroImage[0]?.heading}
    alt="ProtoProto Hero Image"
    loading={"eager"}
  />
  )

  // console.log("new kiberaStoryBuilder" + JSON.stringify(entry[0].kiberaStoryBuilder[0]));

  // return false;

  return (
    <Layout theme="dark" header={headerComponent} showNav={false} seomatic={seomatic}>
      {entry[0]?.kiberaStoryBuilder?.map((block, i) => {
     
        let BlockComponent

        switch (block?.typeHandle) {
          case "collaboration":
            BlockComponent = (
              <CollaborationBlock
                key={i}
                titleField={block?.titleField || ""}
                introduction={block?.introduction}
                description={block?.description}
                leftImage={block?.leftImage?.[0]?.url ? block?.leftImage?.[0]?.url : null}
                rightImage={
                  block?.rightImage?.[0]?.url ? block?.rightImage?.[0]?.url : null
                }
                leftImageCaption={block?.leftImageCaption}
                rightImageCaption={block?.rightImageCaption}
              />
            )
            break
          case "twoPieceLayout":
            console.log(block?.textPosition)
            console.log(block?.textOnImage, block?.caption)
            BlockComponent = (
              <TwoPieceLayoutBlock
                key={i}
                imageSize={block?.imageSize}
                titleSize={block?.titleSize}
                titleText={block?.titleText}
                image={block?.image?.[0]?.url ? block?.image[0]?.url : null}
                textField={block?.textField}
                textPosition={block?.textPosition}
                textOnImage={block?.textOnImage}
                caption={block?.caption}
                imageUrl={block?.imageUrl?.[0]?.length ? block?.imageUrl[0]?.url : null}
              />
            )
            break
          //  Three piece layout block component
          case "threePieceLayout":
            console.log(block.titleSize)
            BlockComponent = (
              <ThreePieceLayoutBlock
                key={i}
                leftImageSize={block?.leftImageSize}
                rightImageSize={block?.rightImageSize}
                titleSize={block?.titleSize}
                titleText={block?.titleText}
                textField={block?.textField}
                secondaryTextField={block?.secondaryTextField}
                textSize={block?.textSize}
                textPosition={block?.textPosition}
                captionLeft={block?.captionLeft}
                captionRight={block?.captionRight}
                textOnImageLeft={block?.textOnImageLeft}
                textOnImageRight={block?.textOnImageRight}
                leftUrl={block?.leftUrl?.[0]?.url ? block?.leftUrl[0]?.url : null}
                rightUrl={block?.rightUrl?.[0]?.url ? block?.rightUrl[0]?.url : null}
                leftImage={block?.leftImage?.[0]?.url ? block.leftImage[0]?.url : null}
                rightImage={
                  block.rightImage?.[0]?.url ? block?.rightImage[0]?.url : null
                }
              />
            )
            break
          // Image block component
          case "image":
            BlockComponent = (
              <React.Fragment key={i}>
                <ImageBlock
                  className="xl:block hidden"
                  image={block.image?.[0]?.url ? block?.image[0]?.url : null}
                  thumbnails={block?.parallaxThumbnails}
                  imageSkew={block.imageSkew.length ? block.imageSkew[0] : null}
                  margins={block?.margin}
                  fullHeight={block?.fullHeight}
                />
                <ImageBlock
                  className="xl:hidden block"
                  image={
                    block.mobileImage.length
                      ? block.mobileImage[0]
                      : block.image.length
                      ? block.image[0]
                      : null
                  }
                  thumbnailsPosition={block?.mobileThumbnailsPosition}
                  thumbnails={block?.mobileThumbnails}
                  parallax={false}
                  imageSkew={
                    block.mobileImageSkew.length
                      ? block.mobileImageSkew[0]
                      : null
                  }
                  margins={block?.mobileMargin}
                  width={block?.mobileWidth}
                  layout={block?.mobileLayout}
                />
              </React.Fragment>
            )
            break
          case "text":
            BlockComponent = (
              <TextBlock
                key={i}
                titleSize={block?.titleSize}
                fullHeight={block?.fullHeight}
                textAlignment={block?.textAlignment}
                textField={block?.textField}
                width={block?.width}
                titleText={block?.titleText}
              />
            )
            break
          default:
            BlockComponent = <div key={i}></div>
            break
        }

        return BlockComponent
      })}
    </Layout>
  )
}


export default KiberaPage
